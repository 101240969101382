const tabsDeployments = document.querySelectorAll('.tabs-deployments')
/* Prevents setMenuHeight from being called again until the current call is complete. */
let isRunning = false

const setMenuHeight = () => {
    if (window.innerWidth > 991 && tabsDeployments.length > 0) {
        const mainHeader = document.querySelector('.main-header')
        const scrollDown = mainHeader.classList.contains('scroll-down')
        const scrollUp = mainHeader.classList.contains('scroll-up')

        for (const tab of tabsDeployments) {
            const nav = tab.querySelector('.nav.nav-pills')
            const currentTop = nav.style.top

            if (scrollDown && currentTop !== '20px') {
                nav.style.top = '20px'
            } else if (scrollUp) {
                const newTop = `${mainHeader.offsetHeight + 20}px`
                if (currentTop !== newTop) {
                    nav.style.top = newTop
                }
            }
        }
    }
    isRunning = false  // Reset the flag after execution
}

const optimizedSetMenuHeight = () => {
    if (!isRunning) {
        isRunning = true
        requestAnimationFrame(setMenuHeight)
    }
}

const initMenuSticky = () => {
    setMenuHeight()  // Initial setup
    window.addEventListener('resize', optimizedSetMenuHeight)
    window.addEventListener('scroll', optimizedSetMenuHeight)
}

export { initMenuSticky }
